<template>
  <footer
    class="bg-red-100 pb-6 pt-7 sm:pt-10"
    aria-labelledby="footer-heading"
  >
    <h2 id="footer-heading" class="sr-only">Footer</h2>
    <UContainer class="max-w-app mx-auto min-w-80 lg:px-0">
      <TnCard>
        <div
          class="flex flex-col items-center lg:grid lg:grid-cols-3 lg:items-start lg:gap-8"
        >
          <div class="grid sm:grid-cols-2 sm:gap-8 lg:col-span-2">
            <div class="text-center sm:text-left">
              <NuxtLink to="/">
                <img
                  src="@/assets/taste-network-logo.svg"
                  alt="Taste Network Logo"
                  class="h-28"
                />
              </NuxtLink>
              <h3
                class="font-roca hidden text-lg font-semibold leading-6 sm:mt-8 sm:block sm:text-3xl"
              >
                It's Personal
              </h3>
            </div>
            <div class="mt-4 text-center sm:mt-2 sm:text-left lg:mt-0">
              <h3 class="text-xs leading-6">EXPLORE</h3>
              <ul role="list" class="mt-2 space-y-1">
                <li v-for="(link, index) in links" :key="index">
                  <ULink
                    :to="link.to"
                    class="font-poppins text-sm font-semibold leading-6 hover:font-bold"
                  >
                    {{ link.label }}
                  </ULink>
                </li>
              </ul>
            </div>
          </div>
          <div class="mt-4 text-center sm:text-right md:mt-2 lg:mt-0">
            <h3
              class="font-poppins mb-2 text-base font-semibold leading-6 text-gray-900 sm:mb-4"
            >
              Have a question?
            </h3>
            <NuxtLink :to="memberSupportEmailLink" target="_blank">
              <UButton
                variant="solid"
                color="tertiary"
                icon="i-heroicons-envelope"
                @click="contactUsClicked"
              >
                Contact Us
              </UButton>
            </NuxtLink>
            <p class="mt-2 text-xs leading-5 text-gray-800">
              <NuxtLink
                to="mailto:support@tastenetwork.io"
                class="hover:text-blue-600 hover:underline"
              >
                support@tastenetwork.io
              </NuxtLink>
            </p>
          </div>
        </div>
      </TnCard>
      <div
        class="mt-4 flex flex-col items-center px-2 text-xs leading-5 text-black opacity-70 sm:flex-row sm:justify-between"
      >
        <div class="text-center">
          &copy; 2010-2024 Wombat Apps LLC. All Rights Reserved.
        </div>
        <div class="mt-6 sm:mt-0">
          <a class="mr-4" :href="TERMS_OF_SERVICE_URL" target="_blank"
            >Terms Of Service</a
          >
          <a :href="PRIVACY_POLICY_URL" target="_blank">Privacy Policy</a>
        </div>
      </div>
    </UContainer>
  </footer>
</template>

<script setup lang="ts">
import EVENTS from '~/constants/events';
import {
  TERMS_OF_SERVICE_URL,
  PRIVACY_POLICY_URL,
} from '@/constants/legalLinks';

const links = computed(() => [
  { label: 'How It Works', to: { name: 'how-it-works' } },
  { label: 'View All Products', to: { name: 'products' } },
  { label: 'FAQ', to: '/members/how-it-works#faq' },
]);

const { $trackEvent } = useNuxtApp();
const route = useRoute();

const contactUsClicked = () => {
  $trackEvent(EVENTS.CONTACT_US_CLICKED, { page: route.meta.eventPageName });
};

const { memberSupportEmailLink } = useUserSupport();
</script>
