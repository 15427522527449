<script setup lang="ts">
import { watch } from 'vue';
import { provideUseId } from '@headlessui/vue';
import { createGlobalState } from '@vueuse/core';
import type { HeaderLink } from '~/types';
import { useCartStore } from '~/stores/cartStore';
import { CartModal } from '#components';
import { HEADERLINKS } from '~/constants/headerLinks';
import EVENTS, { SECTIONS } from '~/constants/events';
import useCurrentUserDetails from '~/composables/useCurrentUserDetails';
import { MemberStatus } from '@tn/shared';

const useUIState = createGlobalState(() => {
  const isHeaderDialogOpen = ref(false);
  return { isHeaderDialogOpen };
});

defineOptions({
  inheritAttrs: false,
});

const attrs = useAttrs();
const { isMobile } = useViewport();
const { isHeaderDialogOpen } = useUIState();
const { clearAuthState, memberUser } = useCurrentUserDetails();
const links = computed(() => HEADERLINKS);

const modal = useModal();
const { $trackEvent, $sentry } = useNuxtApp();

const tokensStore = useTokensStore();
const tokenCount = computed(() => tokensStore.validTokens?.length ?? 0);

const cartStore = useCartStore();
const { isActivePremium, openUpsellModal } = usePremiumCheck();

const cartItemCount = computed(() => cartStore.cartItemsCount);

const route = useRoute();
const hasVerifiedEmailLocally = ref(false);

watch(
  () => route.fullPath,
  () => {
    isHeaderDialogOpen.value = false;
  }
);

provideUseId(() => useId());

const onLogout = async () => {
  try {
    await logout();
    clearAuthState();
    await navigateTo('/login');
  } catch (error) {
    $sentry.captureException(error);
    console.error(error);
  }
};

const onClickRenew = () => {
  openUpsellModal(SECTIONS.WALLET_NOTIFICATION);

  $trackEvent(EVENTS.RENEW_PREMIUM_NOTIFICATION_CLICKED, {
    page: route.meta.eventPageName,
  });
};

const cartClicked = () => {
  if (!modal.isOpen.value) {
    modal.open(CartModal);

    $trackEvent(EVENTS.NAVIGATIONAL_CART_CLICKED, {
      page: route.meta.eventPageName,
      cart_product_count: cartItemCount.value,
    });
  }
};

const linkClicked = (link: HeaderLink) => {
  const linkEventsMap: { [key: string]: string } = {
    Home: EVENTS.HOME_CLICKED,
    'Shop All': EVENTS.SHOP_ALL_CLICKED,
    'How it Works': EVENTS.HOW_IT_WORKS_CLICKED,
    Promotions: EVENTS.PROMOTIONS_CLICKED,
  };

  const eventProperties =
    link.label === 'Promotions'
      ? { page: route.meta.eventPageName, section: SECTIONS.PROMOTIONS_CTA }
      : { page: route.meta.eventPageName };

  $trackEvent(linkEventsMap[link.label], eventProperties);
};

const dropdownOpened = ref(false);

const dropdownIcon = computed(() => {
  return dropdownOpened.value ? 'i-heroicons-x-mark' : 'i-heroicons-user';
});

const items = [
  [
    {
      label: 'Logout',
      slot: 'logout',
    },
  ],
];

const reviewsStore = useReviewsStore();
const auth = useFirebaseAuth();

const shouldShowVerifyEmailAlert = computed(() => {
  if (hasVerifiedEmailLocally.value) {
    return false;
  }

  return (
    typeof auth?.currentUser?.emailVerified !== 'undefined' &&
    !auth.currentUser?.emailVerified &&
    memberUser?.membership === MemberStatus.Premium
  );
});

const onVerify = async () => {
  await auth?.currentUser?.reload();

  if (auth?.currentUser?.emailVerified) {
    hasVerifiedEmailLocally.value = true;
  }
};
</script>

<template>
  <header
    class="max-w-app sticky top-0 z-20 mx-auto w-full min-w-80 px-6 pt-14 lg:px-0"
    v-bind="attrs"
  >
    <nav
      class="bg-beige-400 lg:px-25 relative flex h-12 items-center rounded-full border-b-4 border-l border-r border-t border-black px-2 py-2 md:py-4"
      aria-label="Global"
    >
      <div class="mr-3 flex flex-1 lg:ml-4 lg:mr-0">
        <div
          v-if="links?.length"
          v-bind="attrs"
          :class="[
            'bg-beige-300 absolute inset-x-0 top-full z-10 mx-6 mt-1 flex flex-col items-center justify-between gap-y-8 rounded-[20px] border-b-4 border-l border-r border-t border-black px-3 pb-3 pt-8 md:static md:m-0 md:flex md:flex-row md:gap-x-5 md:border-0 md:bg-transparent md:p-0 md:pl-4',
            { flex: isHeaderDialogOpen, hidden: !isHeaderDialogOpen },
          ]"
        >
          <div
            v-for="(link, index) of links"
            :key="index"
            class="relative mx-auto inline-flex"
          >
            <ULink
              :to="link.to"
              class="whitespace-nowrap text-sm font-bold transition hover:opacity-100 md:font-normal"
              inactive-class="opacity-70"
              active-class="opacity-100 !font-medium"
              @click="linkClicked(link)"
            >
              {{ link.label }}
            </ULink>
          </div>
          <UButton
            color="secondary"
            variant="solid"
            size="sm"
            class="mt-2 md:hidden"
            block
            @click="onLogout"
          >
            Logout
          </UButton>
        </div>
        <UButton
          v-if="links?.length"
          variant="link"
          color="black"
          class="md:hidden"
          :aria-label="`${isHeaderDialogOpen ? 'Close' : 'Open'} Menu`"
          :icon="
            isHeaderDialogOpen
              ? 'i-heroicons-x-mark-20-solid'
              : 'i-heroicons-bars-3-20-solid'
          "
          @click="isHeaderDialogOpen = !isHeaderDialogOpen"
        />
      </div>

      <div
        class="z-20 flex justify-center lg:absolute lg:left-1/2 lg:-translate-x-1/2 lg:transform lg:items-center"
      >
        <NuxtLink to="/" class="w-[7.5rem]">
          <img
            src="@/assets/taste-network-logo.svg"
            alt="Taste Network Logo"
            class="w-full object-contain"
          />
        </NuxtLink>
      </div>

      <div class="flex flex-1 items-center justify-end space-x-4">
        <UPopover
          :open="!isActivePremium || !!reviewsStore?.pendingReviewsAmount"
          :popper="{ arrow: true, placement: 'bottom', offsetDistance: 20 }"
          :ui="{
            background: 'bg-yellow-500',
            ring: 'ring-0 border border-b-4 border-r-4 border-black',
            shadow: 'shadow-none',
            rounded: 'rounded-lg',
            arrow: {
              background: 'before:bg-yellow-500',
              ring: 'before:ring-0 before:border before:border-b-0 before:border-black',
            },
          }"
        >
          <UChip
            size="3xl"
            :text="tokenCount || reviewsStore.pendingReviewsAmount || ''"
            :show="!!tokenCount || !!reviewsStore.pendingReviewsAmount"
            :color="tokenCount ? 'blue' : 'red'"
          >
            <UButton
              color="secondary"
              variant="solid"
              size="sm"
              :to="{ name: 'wallet' }"
              :ui="{ padding: { sm: 'px-2 py-1 sm:px-4 sm:py-1.5' } }"
            >
              <TnTokenIcon size="xs" :disabled="!tokenCount" />
              <span class="hidden lg:block">Wallet</span>
            </UButton>
          </UChip>

          <template #panel>
            <div
              v-if="reviewsStore?.pendingReviewsAmount"
              class="font-secondary p-3 text-xs font-semibold"
            >
              You have {{ reviewsStore?.pendingReviewsAmount }}
              {{
                reviewsStore?.pendingReviewsAmount === 1 ? 'review' : 'reviews'
              }}
              to complete
            </div>
            <div
              v-else
              class="font-secondary flex max-w-[365px] items-center justify-between space-x-4 p-3 text-xs font-semibold"
            >
              <div>
                <h4
                  class="font-poppins mb-1 stroke-black text-base font-black uppercase text-white drop-shadow-[1px_3px_black]"
                >
                  Renew for 25% off
                </h4>
                <p>
                  You don't have an active subscription. Renew to participate in
                  the next sample cycle.
                </p>
              </div>
              <div>
                <UButton
                  color="secondary"
                  variant="solid"
                  size="sm"
                  :ui="{ padding: { sm: 'px-2 py-1 sm:px-4 sm:py-1.5' } }"
                  @click="onClickRenew"
                >
                  Renew
                </UButton>
              </div>
            </div>
          </template>
        </UPopover>

        <UChip
          size="3xl"
          :text="cartItemCount"
          :show="!!cartItemCount"
          color="blue"
        >
          <UButton
            color="secondary"
            variant="solid"
            size="sm"
            icon="i-heroicons-shopping-bag"
            :ui="{
              padding: { sm: 'px-2 py-1 sm:px-4 sm:py-1.5' },
              icon: { size: { sm: 'h-4 w-4' } },
            }"
            @click.stop="cartClicked"
          >
            <span class="hidden lg:block">Cart</span>
          </UButton>
        </UChip>
        <UDropdown
          v-if="!isMobile"
          v-model:open="dropdownOpened"
          :items="items"
          :popper="{ placement: 'bottom-end', arrow: true, offsetDistance: 16 }"
        >
          <UButton
            color="primary"
            variant="solid"
            :ui="{
              padding: { sm: 'px-2 py-1 sm:px-4 sm:py-1.5' },
              icon: { size: { sm: 'h-4 w-4' } },
            }"
            :icon="dropdownIcon"
            size="xs"
            class="p-2"
          >
          </UButton>

          <template #logout="{ item }">
            <UButton
              icon="i-heroicons-arrow-right-end-on-rectangle"
              color="secondary"
              :label="item.label"
              @click="onLogout"
            >
            </UButton>
          </template>
        </UDropdown>
      </div>
    </nav>
  </header>
  <div v-if="shouldShowVerifyEmailAlert">
    <VerifyEmailAlert @verify="onVerify" />
  </div>
</template>
